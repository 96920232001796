import * as React from 'react';
import { styled } from '@mui/material/styles';

import List from '@mui/material/List';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import EditIcon from '@mui/icons-material/Edit';
import PublishIcon from '@mui/icons-material/Publish';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import DialogForm from './dialogs/DialogForm';
import Toaster from './partials/Toaster';

import {publishModelsJson, requestCarImages} from "../../helper/utils";

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  	...theme.typography.body2,
  	padding: theme.spacing(1),
  	textAlign: 'center',
  	color: theme.palette.text.secondary,
}));


let imageFilesX = await requestCarImages({});



export default function ListCarObj(props) {
	const [open, setOpen] = React.useState(false);
	const [obj, setObj] = React.useState({});
	const [objCurrent, setObjCurrent] = React.useState({});
	const [openMsg, setOpenMsg] = React.useState(false);
	const [toasterMsg, setToasterMsg] = React.useState("");	
	const [currentImages, setCurrentImages] = React.useState(imageFilesX);	
 
   	const compareObj = (index) => {
		if(JSON.stringify(props.models[props.type][index]) === JSON.stringify(props.modelsCurrent[props.type][index])) {
	 		return "";			
		}
		else
	 		return "* ";
	};


   	const newCar = () => {
		alert("hier");
  	};



	async function publishModels(params) {		
		setOpenMsg(true);
		setToasterMsg("Publizierung gestartet");

		let data = await publishModelsJson(props.models, props.type);

  	};  	

	
   	const handleClickOpen = (o, oC) => {
		setObj(o);
		setObjCurrent(oC);
    	setOpen(true);
//    	setCurrentImages(data);
  	};
 
  	return (<>
  				<Paper >
	  				<Box sx={{maxHeight: "80vH", minHeight: "70vH"}}>
		  				<List sx={{ width: '100%',  bgcolor: 'background.paper' }}>
							{props.models[props.type].map((model, index) => (
								<>
						      		<ListItem alignItems="block" sx={{display: "block"}}>
										<Stack  direction="row" justifyContent="space-between" alignItems="center">
											<Typography sx={{ flex: '1 1 100%'}}
							          					variant="h6"
							          					id="tableTitle"
							          					component="div"
							        		>
        										<Stack direction="row" alignItems="center" justifyContent="space-between">
													<Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" sx={{margin: "10px"}}/>

													<Box sx={{flexGrow: 1}}
									        		>
														<Typography sx={{ flex: '1 1 100%'}}
										          					variant="h6"
										          					component="div"
										        		>
								        		          	{compareObj(index) + model.label}
															</Typography>
															<Typography sx={{ flex: '1 1 100%'}}
											          					variant="body"
											          					component="span"
											        		>
								        		          	{model.slogan}
														</Typography>
													</Box>
												</Stack>		
        									</Typography>
        									<Typography>
												<IconButton onClick={e => handleClickOpen(model, props.modelsCurrent[props.type][index]) }>
													<EditIcon />
												</IconButton>
        									</Typography>
										</Stack>						        	
						      		</ListItem>
						      		<Divider variant="inset" component="li" />
								</>
							))}
			   			</List>	
				    </Box>	   			
					<hr/>
				  	<Stack className="leftSpacing" direction="row" justifyContent="space-between" alignItems="center">
			        	<Typography gutterBottom variant="h6" component="div">
				          	<Button onClick={e => newCar()} endIcon={<AddCircleOutlineIcon />}>Neues Fahrzeug</Button>
	      				</Typography>
			          	<Typography gutterBottom variant="h6" component="div">
				          	<Button onClick={e => publishModels()} endIcon={<PublishIcon />}>Änderungen publizieren</Button>
			          	</Typography>
			        </Stack>

				</Paper>
				<DialogForm open={open} obj={obj} objCurrrent={objCurrent} handleClose={setOpen} currentImages={currentImages[obj.url]}/>
				<Toaster open={openMsg} msg={toasterMsg} handleClose={setOpenMsg}/>
   			</>
  	);
}