import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

import Grid from '@mui/material/Grid';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import SyncIcon from '@mui/icons-material/Sync';
import EditIcon from '@mui/icons-material/Edit';


import Chart from './partials/Chart';

import {getLeadsTotal, getConfigurationsTotal} from "../../helper/utils";


const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  	...theme.typography.body2,
  	padding: theme.spacing(1),
  	textAlign: 'center',
  	color: theme.palette.text.secondary,
}));

const totalLeads =  await getLeadsTotal();
const totalConfigurations =  await getConfigurationsTotal();



export default function Dashboard(props) {
	const [leads, setLeads] = React.useState(totalLeads.result);
	const [configurations, setConfigurations] = React.useState(totalConfigurations.result);

	async function handleLeads() {		
		const data = await getLeadsTotal();
		setLeads(data.result);
  	};  		
	
	async function handleConfigurations() {		
		const data = await getConfigurationsTotal();
		setConfigurations(data.result);
  	};  		
	
	
	return ( <Box sx={{ flexGrow: 1 }}>
				<Grid container spacing={2}>
        			<Grid item xs={4} sx={{overflow: "hidden"}}>
          				<Item className="backgroundColor1" sx={{display: "flow-root", padding: 0, borderRadius: "15px"}}>
          					<Box  sx={{fontSize: "32px"}}>
          						Leads
          					</Box>
          					<Box  sx={{fontSize: "32px", position: "absolute"}}>
          						{leads}
								<br/>
								<span style={{fontSize: "16px"}}>Zeitraum:</span>
								<br/>
								<IconButton onClick={handleLeads}><SyncIcon /></IconButton>
								<IconButton onClick={e => props.changeMenu("Leads")}><KeyboardDoubleArrowRightIcon /></IconButton>
          					</Box>
				          	<svg xmlns="http://www.w3.org/2000/svg" 
				          		  aria-hidden="true" role="img" 
								 style={{float: "right"}}
				          		 className="component-iconify MuiBox-root iconify iconify--fluent bgIcon" 
				          		 width="1em" 
				          		 height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M22 8.608v8.142a3.25 3.25 0 0 1-3.066 3.245L18.75 20H5.25a3.25 3.25 0 0 1-3.245-3.066L2 16.75V8.608l9.652 5.056a.75.75 0 0 0 .696 0zM5.25 4h13.5a3.25 3.25 0 0 1 3.234 2.924L12 12.154l-9.984-5.23a3.25 3.25 0 0 1 3.048-2.919zh13.5z"></path></svg>          
          
						</Item>
        			</Grid>
        			<Grid item xs={4} sx={{overflow: "hidden"}}>
          				<Item className="backgroundColor2" sx={{display: "flow-root", padding: 0, borderRadius: "15px"}}>
          					<Box onClick={handleConfigurations} sx={{fontSize: "32px"}}>
          						Konfigurationen
          					</Box>
          					<Box  sx={{fontSize: "32px", position: "absolute"}}>
          						{configurations}
								<br/>
								<span style={{fontSize: "16px"}}>Zeitraum:</span>
								<br/>
								<IconButton onClick={handleConfigurations}><SyncIcon /></IconButton>
								<IconButton onClick={e => props.changeMenu("Konfigurationen")}><KeyboardDoubleArrowRightIcon /></IconButton>
								
          					</Box>
				          	<svg xmlns="http://www.w3.org/2000/svg" 
				          		  aria-hidden="true" role="img" 
								 style={{float: "right"}}
				          		 className="component-iconify MuiBox-root iconify iconify--fluent bgIcon" 
				          		 width="1em" 
				          		 height="1em" viewBox="0 0 24 24"><path d="M9 15c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4m13.1-8.16c.01-.11.02-.22.02-.34 0-.12-.01-.23-.03-.34l.74-.58c.07-.05.08-.15.04-.22l-.7-1.21c-.04-.08-.14-.1-.21-.08l-.86.35c-.18-.14-.38-.25-.59-.34l-.13-.93c-.02-.09-.09-.15-.18-.15h-1.4c-.09 0-.16.06-.17.15l-.13.93c-.21.09-.41.21-.59.34l-.87-.35c-.08-.03-.17 0-.21.08l-.7 1.21c-.04.08-.03.17.04.22l.74.58c-.02.11-.03.23-.03.34 0 .11.01.23.03.34l-.74.58c-.07.05-.08.15-.04.22l.7 1.21c.04.08.14.1.21.08l.87-.35c.18.14.38.25.59.34l.13.93c.01.09.08.15.17.15h1.4c.09 0 .16-.06.17-.15l.13-.93c.21-.09.41-.21.59-.34l.87.35c.08.03.17 0 .21-.08l.7-1.21c.04-.08.03-.17-.04-.22zm-2.6.91c-.69 0-1.25-.56-1.25-1.25s.56-1.25 1.25-1.25 1.25.56 1.25 1.25-.56 1.25-1.25 1.25m.42 3.93-.5-.87c-.03-.06-.1-.08-.15-.06l-.62.25c-.13-.1-.27-.18-.42-.24l-.09-.66c-.02-.06-.08-.1-.14-.1h-1c-.06 0-.11.04-.12.11l-.09.66c-.15.06-.29.15-.42.24l-.62-.25c-.06-.02-.12 0-.15.06l-.5.87c-.03.06-.02.12.03.16l.53.41c-.01.08-.02.16-.02.24 0 .08.01.17.02.24l-.53.41c-.05.04-.06.11-.03.16l.5.87c.03.06.1.08.15.06l.62-.25c.13.1.27.18.42.24l.09.66c.01.07.06.11.12.11h1c.06 0 .12-.04.12-.11l.09-.66c.15-.06.29-.15.42-.24l.62.25c.06.02.12 0 .15-.06l.5-.87c.03-.06.02-.12-.03-.16l-.52-.41c.01-.08.02-.16.02-.24 0-.08-.01-.17-.02-.24l.53-.41c.05-.04.06-.11.04-.17m-2.42 1.65c-.46 0-.83-.38-.83-.83 0-.46.38-.83.83-.83s.83.38.83.83c0 .46-.37.83-.83.83M4.74 9h8.53c.27 0 .49-.22.49-.49v-.02c0-.27-.22-.49-.49-.49H13c0-1.48-.81-2.75-2-3.45v.95c0 .28-.22.5-.5.5s-.5-.22-.5-.5V4.14C9.68 4.06 9.35 4 9 4s-.68.06-1 .14V5.5c0 .28-.22.5-.5.5S7 5.78 7 5.5v-.95C5.81 5.25 5 6.52 5 8h-.26c-.27 0-.49.22-.49.49v.03c0 .26.22.48.49.48M9 13c1.86 0 3.41-1.28 3.86-3H5.14c.45 1.72 2 3 3.86 3"></path></svg>          
          
						</Item>
        			</Grid>
        			<Grid item xs={4} sx={{overflow: "hidden"}}>
          				<Item className="backgroundColor3" sx={{display: "flow-root", padding: 0, borderRadius: "15px"}}>
          					<Box onClick={handleConfigurations} sx={{fontSize: "32px"}}>
          						Anmeldungen
          					</Box>
          					<Box  sx={{fontSize: "32px", position: "absolute"}}>
          						{0}
								<br/>
								<span style={{fontSize: "16px"}}>Zeitraum:</span>
								<br/>
								<IconButton onClick={handleConfigurations}><SyncIcon /></IconButton>
								<IconButton onClick={e => props.changeMenu("Konfigurationen")}><KeyboardDoubleArrowRightIcon /></IconButton>
								
          					</Box>
				          	<svg xmlns="http://www.w3.org/2000/svg" 
				          		  aria-hidden="true" role="img" 
								 style={{float: "right"}}
				          		 className="component-iconify MuiBox-root iconify iconify--fluent bgIcon" 
				          		 width="1em" 
				          		 height="1em" viewBox="0 0 24 24"><path d="M19 4h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2m0 16H5V10h14zM9 14H7v-2h2zm4 0h-2v-2h2zm4 0h-2v-2h2zm-8 4H7v-2h2zm4 0h-2v-2h2zm4 0h-2v-2h2z"></path></svg>          
          
						</Item>
        			</Grid>      			</Grid>

				<Grid container spacing={2} sx={{mt: 2}}>
        			<Grid item xs={8} sx={{}}>
          				<Paper className="" sx={{ borderRadius: "10px"}}>
          					<Box  sx={{fontSize: "32px"}}>
          						Top 5 Cars
          					</Box>
          					<Box  sx={{}}>

				  				<List sx={{ width: '100%',  bgcolor: 'background.paper' }}>
									{props.models.models.map((model, index) => (
										<>
										{index > 4 ? (<></>):(
								      		<ListItem alignItems="block" sx={{display: "block"}}>
												<Stack  direction="row" justifyContent="space-between" alignItems="center">
													<Typography sx={{ flex: '1 1 100%'}}
									          					variant="h6"
									          					id="tableTitle"
									          					component="div"
									        		>
		        										<Stack direction="row" alignItems="center" justifyContent="space-between">
															<Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" sx={{margin: "10px"}}/>
		
															<Box sx={{flexGrow: 1}}
											        		>
																<Typography sx={{ flex: '1 1 100%'}}
												          					variant="h6"
												          					component="div"
												        		>
									        		          	{model.label}
																</Typography>
																<Typography sx={{ flex: '1 1 100%'}}
												          					variant="body"
												          					component="span"
												        		>
									        		          	{model.slogan}
																</Typography>
															</Box>
														</Stack>		
		        									</Typography>
		        									<Typography>
														<IconButton >
															<EditIcon />
														</IconButton>
		        									</Typography>
												</Stack>						        	
								      		</ListItem>
								      		)}
										</>
									))}
					   			</List>


          					</Box>
          
						</Paper>
        			</Grid>

					<Grid item xs={4} sx={{overflow: "hidden"}}>
						<Paper className="" sx={{ borderRadius: "10px"}}>
							<Chart/>
						</Paper>

        			</Grid>
      			</Grid>

    		</Box>
  		);
}

