import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Rating from '@mui/material/Rating';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import schema from '../forms/schema.json';
import ImagePicker from './ImagePicker.js';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));


function TextWidget(props) {
	const handleChange = (e) => {
		props.obj[props.objKey] = e.target.value;
	};

	return (
		<>
			<TextField 	id="outlined-basic" 
						label={props.label} 
						defaultValue={props.value} 
						onChange={e => handleChange(e)}
						variant="outlined" 
						sx={{minWidth:"90%", marginBottom: "10px"}}/>
			<br/>	
		</>	
	);
}

function NumberWidget(props) {
	const handleChange = (e) => {
		props.obj[props.objKey] = e.target.value;
	};

	return (
		<>
			<TextField 	type="number"
						step="any"
			  			label={props.label} 
						onChange={e => handleChange(e)}
			  			InputProps={{ inputProps: { min: 0, max: 40 } }}
			  			defaultValue={props.value}
			  			variant="outlined"
			 			sx={{minWidth:"50%", marginBottom: "10px"}}/>
			<br/>	
		</>	
	);
}

function IntegerWidget(props) {
	const handleChange = (e) => {
		props.obj[props.objKey] = e.target.value;
	};

	return (
		<>
			<TextField 	type="number"
						step="any"
			  			label={props.label} 
						onChange={e => handleChange(e)}
			  			InputProps={{ inputProps: { min: 0, max: 256 } }}
			  			defaultValue={props.value}
			  			variant="outlined"
			 			sx={{minWidth:"50%", marginBottom: "10px"}}/>
			<br/>	
		</>	
	);
}


function ProducerWidget(props) {
	const handleChange = (e) => {
		props.obj[props.objKey] = e.target.value;
	};
	
	return (
		<>
			<FormControl sx={{minWidth:"50%", marginBottom: "10px"}}>
			  <InputLabel id="producer-select-label">Hersteller</InputLabel>
			  <Select
			    labelId="producer-select-label"
			    id="producer-select"
			    label="Hersteller"
	  			value={props.value}
			    onChange={e => handleChange(e)}
			  >
			    <MenuItem value={'vw-logo.png'}>VW</MenuItem>
			    <MenuItem value={'mb-logo.png'}>Mercedes</MenuItem>
			    <MenuItem value={'ford-logo.png'}>Ford</MenuItem>
			  </Select>
			</FormControl>
		</>	
	);
}

function TabWidget(props) {
	return (
		<>
			huhu
			<br/>	
		</>	
	);
}

function ImageWidget(props) {
	const [open, setOpen] = React.useState(false);
	const [val, setVal] = React.useState(props.value);

	const handleChange = (value) => {
		props.obj[props.objKey] = value;
		setVal(value);
	};


   	const handleClickOpen = () => {
    	setOpen(true);
  	};

	return (
		<>
			<Grid container style={{paddingBottom: "10px"}}>
				<Grid item xs={8}>
	
					<TextField 	id="outlined-basic" 
								label={props.label} 
								value={val} 
								variant="outlined" 
								multiline
								sx={{minWidth:"90%", marginBottom: "10px"}}/>
					
	   			</Grid>		
					
				<Grid item xs={4}>					
					<Button variant="contained" 
							onClick={handleClickOpen}
							endIcon={<InsertPhotoIcon />}>
					  auswählen
					</Button>					
	
	   			</Grid>		
			</Grid>		
			<ImagePicker open={open} handleChange={handleChange} handleClose={setOpen} currentImage={val} currentImages={props.currentImages}/>
		
		</>	
	);
}

function ImagesWidget(props) {
	const [open, setOpen] = React.useState(false);
	const [val, setVal] = React.useState(props.value);

	const handleChange = (value) => {
		props.obj[props.objKey] = value;
		setVal(value);
	};


   	const handleClickOpen = () => {
    	setOpen(true);
  	};

	return (
		<>
			<Grid container style={{paddingBottom: "10px"}}>
				<Grid item xs={8}>
	
					<TextField 	id="outlined-basic" 
								label={props.label} 
								value={val} 
								variant="outlined" 
								multiline
								sx={{minWidth:"90%", marginBottom: "10px"}}/>
					
	   			</Grid>		
					
				<Grid item xs={4}>					
					<Button variant="contained" 
							onClick={handleClickOpen}
							endIcon={<InsertPhotoIcon />}>
					  auswählen
					</Button>					
	
	   			</Grid>		
			</Grid>		
			<ImagePicker open={open} handleChange={handleChange} handleClose={setOpen} currentImage={val} currentImages={props.currentImages}/>
		
		</>	
	);
}

function RatingWidget(props) {
	const [everyday, setEveryday] = React.useState(props.value.Everyday);
	const [cityTrips, setCityTrips] = React.useState(props.value.CityTrips);
	const [suitableForFamilies, setSuitableForFamilies] = React.useState(props.value.SuitableForFamilies);
	const [longerTrips, setLongerTrips] = React.useState(props.value.LongerTrips);

	const handleChange = (e, v) => {
		props.value[e.target.name] = v;
		if(e.target.name == "Everyday")
			setEveryday(v);
		if(e.target.name == "CityTrips")
			setCityTrips(v);
		if(e.target.name == "SuitableForFamilies")
			setSuitableForFamilies(v);
		if(e.target.name == "LongerTrips")
			setLongerTrips(v);
	};
	
	
	return (
		<>
			<Typography component="legend">Everyday</Typography>			
			<Rating
		        name="Everyday"
		        value={everyday}
				onChange={(e, v) => handleChange(e, v)}
		      />			
			<Typography component="legend">CityTrips</Typography>			
			<Rating
		        name="CityTrips"
		        value={cityTrips}
				onChange={(e, v) => handleChange(e, v)}
		      />			
			<Typography component="legend">SuitableForFamilies</Typography>			
			<Rating
		        name="SuitableForFamilies"
		        value={suitableForFamilies}
				onChange={(e, v) => handleChange(e, v)}
		      />			
			<Typography component="legend">LongerTrips</Typography>			
			<Rating
		        name="LongerTrips"
		        value={longerTrips}
				onChange={(e, v) => handleChange(e, v)}
		      />			
			<br/>	
		</>	
	);
}


function Widget(props) {
	const widgetProps = schema.properties[props.objkey];
	return (<>
				{widgetProps != undefined ? (<>
												{widgetProps.type == "string" ?	(<>
																					<TextWidget label={widgetProps.title} 
																								value={props.value}
																								objKey={props.objkey}
																								obj={props.obj}
																					/>
																				</>) : (<></>)}
												{widgetProps.type == "number" ?	(<>
																					<NumberWidget 	label={widgetProps.title} 
																									value={props.value}
																									objKey={props.objkey}
																									obj={props.obj}
																								/>
																				</>) : (<></>)}
												{widgetProps.type == "integer" ?	(<>
																					<IntegerWidget 	label={widgetProps.title} 
																									objKey={props.objkey}
																									value={props.value}
																									obj={props.obj}
																								/>
																				</>) : (<></>)}
												{widgetProps.type == "producer" ?	(<>
																					<ProducerWidget label={widgetProps.title} 
																									objKey={props.objkey}
																									value={props.value}
																									obj={props.obj}
																								/>
																				</>) : (<></>)}
												{widgetProps.type == "image" ?	(<>
																					<ImageWidget 	label={widgetProps.title} 
																									objKey={props.objkey}
																									value={props.value}
																									obj={props.obj}
																									currentImages={props.currentImages}
																								/>
																				</>) : (<></>)}
												{widgetProps.type == "images" ?	(<>
																					<ImagesWidget 	label={widgetProps.title} 
																									objKey={props.objkey}
																									value={props.value}
																									obj={props.obj}
																									currentImages={props.currentImages}
																								/>
																				</>) : (<></>)}
												{widgetProps.type == "tabs" ?	(<>
																					<TabWidget label={widgetProps.title} 
																								value={props.value}
																								objKey={props.objkey}
																								/>
																				</>) : (<></>)}
												{widgetProps.type == "rating" ?	(<>
																					<RatingWidget 	label={widgetProps.title} 
																									value={props.value}
																									objKey={props.objkey}
																									obj={props.obj}
																								/>
																				</>) : (<></>)}
							  
											 </>)
				 :(<></>)
				}
			</>	
	);
}

export default function CustomizedFormDialogs(props) {

//	console.log(props.currentImages);

	
	const handleClose = () => {
		props.obj.label = props.objCurrrent.label;
		props.handleClose(false);
	};

  	const handleChange = () => {
    	props.handleClose(false);
	};

 	return (<React.Fragment>
	      		<BootstrapDialog 	onClose={handleClose}
	        						aria-labelledby="customized-dialog-title"
	        						open={props.open}
	        						
	      		>
		        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
		          Datenpflege
		        </DialogTitle>
	        		<IconButton aria-label="close"
	          					onClick={handleClose}
						          sx={{
						            position: 'absolute',
						            right: 8,
						            top: 8,
						            color: (theme) => theme.palette.grey[500],
						          }}
	        		>
	 					<CloseIcon />
					</IconButton>
	        	<DialogContent dividers>
					<Box sx={{minHeight: "300px", minWidth: "600px"}}>						
						 { 
						   Object.entries(props.obj).map(([k, v]) => {
						     return <><Widget objkey={k} value={v} obj={props.obj} currentImages={props.currentImages}/></>
						   })
						 }
						
						
					</Box >	
	        	</DialogContent>
	        <DialogActions>
	          <Button autoFocus onClick={handleClose}>
	            Cancel
	          </Button>
	          <Button autoFocus onClick={handleChange}>
	            Save
	          </Button>
	        </DialogActions>
      </BootstrapDialog>

    </React.Fragment>
  );
}