import axios from "axios";

export const msgSend = (msg)  => {
	return "processing";
}

export const calcMonth = (amount)  => {
	let number =  (amount+(amount*6.99/100)) / 60;
	return Number(number).toLocaleString("de-DE", {minimumFractionDigits: 2, maximumFractionDigits: 2}) + " €";
}

export const calcTotal = (amount)  => {
	let number =  (amount+(amount*6.99/100));
	return Number(number).toLocaleString("de-DE", {minimumFractionDigits: 2, maximumFractionDigits: 2}) + " €";
}

export const formatNumber = (local, number)  => {
	if (isNaN(number))
		return "";
	else	
		return Number(number).toLocaleString("de-DE", {minimumFractionDigits: 2, maximumFractionDigits: 2}) + " €";
}

export async function getExternal(code, params) {
	    const {data} = await axios.get(process.env.REACT_APP_BACKEND_HOST + "/v1/external/configuration/vw/"+code, 
	    params,
	        {headers: {'x-profile-key': "profileKey"}}
	        );
	    return data; 
}

export async function getConfigurationCode(params) {
	    const {data} = await axios.get(process.env.REACT_APP_BACKEND_HOST + "/v1/action/configurations/newCode", 
	    params,
	        {headers: {'x-profile-key': "profileKey"}}
	        );

		var db = JSON.stringify(data);
		let result = JSON.parse("{" + data + "}");

	    return result; 
}




export async function requestCarImages(params) {
	    const {data} = await axios.get(process.env.REACT_APP_BACKEND_HOST + "/v1/action/requestCarImages", 
	    params,
	        {headers: {'Content-Type': "application/json"}}
	        );
//		let result = JSON.parse("{" + data + "}");

	    return data; 
}

export async function getCarImages(params, path) {
	    const {data} = await axios.get(process.env.REACT_APP_BACKEND_HOST + "/v1/action/requestGitTree/" + path, 
	    params,
	        {headers: {'Content-Type': "application/json"}}
	        );
//		let result = JSON.parse("{" + data + "}");

	    return data; 
}


export async function requestConfigPdf(obj, id) {
	    const {data} = await axios.get(process.env.REACT_APP_BACKEND_HOST +  "/v1/action/requestConfigPdf/" + id, 
	    	obj,
	        {headers: { 'Content-Type': "application/json"
	        		  }
	        }
	     );
	    return data; 
}

export async function getConfigurations(params) {
	    const {data} = await axios.get(process.env.REACT_APP_BACKEND_HOST + "/v1/action/configurations/", 
	    params,
	        {headers: {'x-profile-key': "profileKey"}}
	        );


	    return data; 
}


export async function getConfigurationsTotal(params) {
	    const {data} = await axios.get(process.env.REACT_APP_BACKEND_HOST + "/v1/action/configurations/total", 
	    params,
	        {headers: {'x-profile-key': "profileKey"}}
	        );

		var db = JSON.stringify(data);
		let result = JSON.parse("{" + data + "}");

	    return result; 
}

export async function postLanguage(obj, lng) {
	    
	    const {data} = await axios.post(process.env.REACT_APP_BACKEND_HOST +  "/v1/action/postLanguage/" + lng,
	    	obj,
	        {headers: { 'Content-Type': "application/json"
	        		  }
	        }
	     );
	    return data; 	    
}

export async function doLogin(obj) {
	    
	    const {data} = await axios.post(process.env.REACT_APP_BACKEND_HOST +  "/v1/action/login",
	    	obj,
	        {headers: { 'Content-Type': "application/json"
	        		  }
	        }
	     );
	    return data; 	    
}

export async function postTranslations(obj, type, lng) {
	    
	    const {data} = await axios.post(process.env.REACT_APP_BACKEND_HOST +  "/v1/action/translations/"+ lng +"/" + type,
	    	obj,
	        {headers: { 'Content-Type': "application/json"
	        		  }
	        }
	     );
	    return data; 	      
}

export async function getTranslations(params) {
	    const {data} = await axios.get(process.env.REACT_APP_BACKEND_HOST + "/v1/action/translations/", 
	    params,
	        {headers: {'x-profile-key': "profileKey"}}
	        );

	    return data; 
}


export async function getLeads(params) {
	    const {data} = await axios.get(process.env.REACT_APP_BACKEND_HOST + "/v1/action/leads/", 
	    params,
	        {headers: {'x-profile-key': "profileKey"}}
	        );

	    return data; 
}

export async function getLeadsTotal(params) {
	    const {data} = await axios.get(process.env.REACT_APP_BACKEND_HOST + "/v1/action/leads/total", 
	    params,
	        {headers: {'x-profile-key': "profileKey"}}
	        );

		var db = JSON.stringify(data);
		let result = JSON.parse("{" + data + "}");

	    return result; 
}


export async function publishTranslationsJson(obj, type) {
	    const {data} = await axios.post(process.env.REACT_APP_BACKEND_HOST +  "/v1/action/publishTranslationsJson/" + type, 
	    	obj,
	        {headers: { 'Content-Type': "application/json"
	        		  }
	        }
	     );
	    return data; 
}

export async function publishModelsJson(obj, type) {
	    const {data} = await axios.post(process.env.REACT_APP_BACKEND_HOST +  "/v1/action/publishModelsJson/" + type, 
	    	obj,
	        {headers: { 'Content-Type': "application/json"
	        		  }
	        }
	     );
	    return data; 
}

export async function publishLatestNewsJson(obj) {
	    const {data} = await axios.post(process.env.REACT_APP_BACKEND_HOST +  "/v1/action/publishLatestNewsJson/", 
	    	obj,
	        {headers: { 'Content-Type': "application/json"
	        		  }
	        }
	     );
	    return data; 
}


export async function sendAction(obj, type) {
	    const {data} = await axios.post(process.env.REACT_APP_BACKEND_HOST +  "/v1/action/sendmail/" + type, 
	    	obj,
	        {headers: { 'Content-Type': "application/json"
	        		  }
	        }
	     );
	    return data; 
}

export async function createOfferPdf(obj, type) {
	    const {data} = await axios.post(process.env.REACT_APP_BACKEND_HOST +  "/v1/action/createoffer/" + type, 
	    	obj,
	        {headers: { 'Content-Type': "application/json"
	        		  }
	        }
	     );
	    return data; 
}
