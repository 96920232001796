import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";

import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AirlineSeatFlatIcon from '@mui/icons-material/AirlineSeatFlat';
import AirlineSeatReclineNormalIcon from '@mui/icons-material/AirlineSeatReclineNormal';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import ScaleIcon from '@mui/icons-material/Scale';

import Header from "./common/Header2";
import IntroDescriptionPage from "../snippets/IntroDescriptionPage";
import ContactPersons from "../widgets/ContactPersons";
import Usefull from "../snippets/Usefull";



const images = [
  'Slide1HeroCompare',
  'Slide1HeroCompare',
  'Slide1HeroCompare',
];


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  	children: PropTypes.node,
  	index: PropTypes.number.isRequired,
  	value: PropTypes.number.isRequired,
};


function CampingContent(props) {
	const { t, i18n } = useTranslation("",{lng: props.language});

  	const [ leftCarId, setLeftCarId] = React.useState(props.leftCar);
  	const [ rightCarId, setRightCarId] = React.useState(props.rightCar);

	var models = require('../db/models.json');

  	const getCar = (which) => {
		let car;
		models.cars.forEach((value, keys) => {
			if(value.url == which)
		  		car = value;
	  })
	  return car;
  	};
  	
  	const getModel = (which) => {
		let model;
		models.models.forEach((value, keys) => {
			if(value.id == which)
		  		model = value;
	  })
	  return model;
  	};

	var leftCar = getCar(leftCarId);
	var rightCar = getCar(rightCarId);
  	
  	return (<>
			  	<Header heroImages={images} label={t("Vergleichen")} slogan={t("VergleichenSlogan")}  breadcrumb={props.props.breadcrumb} embedded={props.props.embedded}/>

				<Box sx={{ flexGrow: 1, display: {
							xs: 'block',
							sm: 'block',  
							md: 'block',  
							lg: 'block',  
							xl: 'block',  
							
							} }}
				>



	  				<Box  sx={{paddingTop: "0px", marginTop: "0px"}} > 

						<IntroDescriptionPage description={t("CompareDescription")}/>

						<Grid container >
							<Grid item xs={12}>
								<Grid container spacing={2}>
									<Grid item xs={0} sm={1} md={2} lg={2} xl={2}>
									</Grid>
									<Grid 	item 
											xs={12} sm={5} md={4} lg={4} xl={4} 
										  	sx={{	backgroundColor: "#f2f2f2", 
										  			borderRight: "2px solid white", 
										  			borderRadius: "5px"
										  		}}
									>
						        		<center>
											<Card sx={{border: "none", boxShadow: "none", backgroundColor: "#f2f2f2", paddingTop: "30px"}} >
												<img src={leftCar.imagePlain} width="300"/>

								      			<CardContent sx={{minHeight: "40px"}}>
											        <Typography variant="h5" color="text.secondary">
														{t("UVP")}<br/>{t("ab")} {Number(leftCar.price).toLocaleString("de-DE", {minimumFractionDigits: 2}) + " €"}
											        </Typography>

													<FormControl variant="standard" sx={{ m: 1, minWidth: 120, fontSize: "18px",backgroundColor: "white" }}>
															<Select labelId="demo-simple-select-standard-label"
													          		id="demo-simple-select-standard"
													          		value={leftCarId}
													          		sx={{fontSize: "20px" }}
													          		onChange={e => setLeftCarId(e.target.value)}
													        >
																{models.cars.map((model) => (
														          	<MenuItem value={model.url}>{model.label}</MenuItem>
																))}
													        </Select>
												      </FormControl>

												</CardContent>
							        		</Card>
							        	</center>


									</Grid>
									<Grid 	item 
											xs={12} sm={5} md={4} lg={4} xl={4} 
											sx={{	backgroundColor: "hsla(0,0%,94.9%,.5)", 
													borderRadius: "5px"
												}}
									>
						        		<center>
											<Card sx={{border: "none", boxShadow: "none", backgroundColor: "inherit", paddingTop: "30px"}} >
												<img src={rightCar.imagePlain} width="300"/>

								      			<CardContent sx={{minHeight: "40px"}}>
											        <Typography variant="h5" color="text.secondary">
														{t("UVP")}<br/>{t("ab")} {Number(rightCar.price).toLocaleString("de-DE", {minimumFractionDigits: 2}) + " €"}
											        </Typography>
											        
													<FormControl variant="standard" sx={{ m: 1, minWidth: 120, fontSize: "18px",backgroundColor: "white" }}>
															<Select labelId="demo-simple-select-standard-label"
													          		id="demo-simple-select-standard"
													          		value={rightCarId}
													          		sx={{fontSize: "20px" }}
													          		onChange={e => setRightCarId(e.target.value)}
													        >
																{models.cars.map((model) => (
														          	<MenuItem value={model.url}>{model.label}</MenuItem>
																))}
													        </Select>
												      </FormControl>
											        
											        
												</CardContent>
							        		</Card>
							        	</center>


									</Grid>
									<Grid item  xs={0} sm={1} md={2} lg={2} xl={2}>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
						
						<Grid container sx={{paddingLeft: "0px"}} >
							<Grid item xs={12} sx={{paddingLeft: "0px"}}>
								<Grid container spacing={2} >
									<Grid item xs={0} sm={1} md={1} lg={2} xl={2} sx={{paddingLeft: "0px"}}>
									</Grid>
									<Grid item xs={12} sm={10} md={10} lg={8} xl={8} sx={{paddingLeft: "0px !important"}}>
									
										<Box sx={{border: "0px solid #eee", marginTop: "20px",padding: "0 !important", borderRadius: "0px"}}>
											<ReactCompareSlider
											  ref={{
											    current: '[Circular]'
											  }}
											  boundsPadding={0}
											  itemOne={<ReactCompareSliderImage alt="Image one" src={leftCar.compareImage1}/>}
											  itemTwo={<ReactCompareSliderImage alt="Image two" src={rightCar.compareImage1} style={{backgroundColor: 'white', backgroundImage: '\n linear-gradient(45deg, #ccc 25%, transparent 25%),\n linear-gradient(-45deg, #ccc 25%, transparent 25%),\n linear-gradient(45deg, transparent 75%, #ccc 75%),\n linear-gradient(-45deg, transparent 75%, #ccc 75%)', backgroundPosition: '0 0, 0 10px, 10px -10px, -10px 0px', backgroundSize: '20px 20px'}}/>}
											  keyboardIncrement="5%"
											  position={50}
		
											  transition=".75s ease-in-out"
											/>					        		
					        			</Box>									
									
									
									</Grid>						
									<Grid item xs={0} sm={1} md={1} lg={2} xl={2} sx={{paddingLeft: "0px"}}>
									</Grid>
								</Grid>
							</Grid>
						</Grid>


						<Grid container sx={{marginTop: "50px"}}>
							<Grid item xs={12}>
								<Grid container spacing={2} >
									<Grid item xs={0} sm={1} md={1} lg={2} xl={2}>
									</Grid>
									<Grid item xs={12} sm={10} md={10} lg={8} xl={8} sx={{paddingLeft: "0px !important"}}>
										<Accordion sx={{padding: "0px"}} defaultExpanded={true}>
									        <AccordionSummary
									          expandIcon={<ExpandMoreIcon />}
									          aria-controls="panel1-content"
									          id="panel1-header"
									        >
									          <h3 sx={{padding: "0"}}><b>{t("Grund Fahrzeug")}</b></h3>
									        </AccordionSummary>
									        <AccordionDetails>			

												<Grid container >
													<Grid item xs={12}>
														<Grid container spacing={2} sx={{width: 'calc(100% + 32px)'}}>
															<Grid item xs={12} sx={{backgroundColor: "#fff"}}>
																<center>
																	<h3 class="headlineCompare">{t("Abmessung")}</h3>
																</center>
															</Grid>
															<Grid item xs={6} sx={{backgroundColor: "#f2f2f2", padding: 0, borderRight: "2px solid white"}}>
																<center>
																	<ul class="empty2" >
																		<li><ViewInArIcon sx={{fontSize: "1.5em"}}/>{leftCar.size.outerLength}cm x {leftCar.size.outerHeight}cm x {leftCar.size.outerWidth}cm</li>
																	</ul>
																</center>											        			
															</Grid>
															<Grid item xs={6} sx={{backgroundColor: "hsla(0,0%,94.9%,.5)", padding: 0}}>
																<center>
																	<ul class="empty2" >
																		<li><ViewInArIcon sx={{fontSize: "1.5em"}}/>{rightCar.size.outerLength}cm x {rightCar.size.outerHeight}cm x {rightCar.size.outerWidth}cm</li>
																	</ul>											        																		
																</center>
															</Grid>

															<Grid item xs={12} sx={{backgroundColor: "#fff"}}>
																<center>
																	<h3 class="headlineCompare">{t("Gewicht")}</h3>
																</center>
															</Grid>
															<Grid item xs={6} sx={{backgroundColor: "#f2f2f2", padding: 0, borderRight: "2px solid white"}}>
																<center>
																	<ul class="empty2" >
																		<li><ScaleIcon sx={{fontSize: "1.5em"}}/>{leftCar.weight}kg</li>
																	</ul>
																</center>											        			
															</Grid>
															<Grid item xs={6} sx={{backgroundColor: "hsla(0,0%,94.9%,.5)", padding: 0}}>
																<center>
																	<ul class="empty2" >
																		<li><ScaleIcon sx={{fontSize: "1.5em"}}/>{rightCar.weight}kg</li>
																	</ul>											        																		
																</center>
															</Grid>



															<Grid item xs={12} sx={{backgroundColor: "#fff"}}>
																<center>
																	<h3 class="headlineCompare">{t("seats")}</h3>
																</center>
															</Grid>
															<Grid item xs={6} sx={{backgroundColor: "#f2f2f2", padding: 0, borderRight: "2px solid white"}}>
																<center>
																	<ul class="empty2" >
																		<li ><AirlineSeatReclineNormalIcon sx={{fontSize: "1.5em"}}/>{leftCar.seats} + {leftCar.seatsOptional}</li>
																	</ul>
																</center>											        			
															</Grid>
															<Grid item xs={6} sx={{backgroundColor: "hsla(0,0%,94.9%,.5)", padding: 0}}>
																<center>
																	<ul class="empty2" >
																		<li ><AirlineSeatReclineNormalIcon sx={{fontSize: "1.5em"}}/>{rightCar.seats} + {rightCar.seatsOptional}</li>
																	</ul>											        																		
																</center>
															</Grid>

															<Grid item xs={12} sx={{backgroundColor: "#fff"}}>
																<center>
																	<h3 class="headlineCompare">{t("sleepingPlaces")}</h3>
																</center>
															</Grid>
															<Grid item xs={6} sx={{backgroundColor: "#f2f2f2", padding: 0, borderRight: "2px solid white"}}>
																<center>
																	<ul class="empty2" >
																		<li><AirlineSeatFlatIcon sx={{fontSize: "1.5em"}}/>{leftCar.sleepingplaces} + {leftCar.sleepingplacesOptional}</li>
																	</ul>
																</center>											        			
															</Grid>
															<Grid item xs={6} sx={{backgroundColor: "hsla(0,0%,94.9%,.5)", padding: 0}}>
																<center>
																	<ul class="empty2" >
																		<li><AirlineSeatFlatIcon sx={{fontSize: "1.5em"}}/>{rightCar.sleepingplaces} + {rightCar.sleepingplacesOptional}</li>
																	</ul>											        																		
																</center>
															</Grid>
															
														</Grid>

													</Grid>
												</Grid>


									        </AccordionDetails>
										</Accordion>


										<Accordion sx={{padding: "0px"}} defaultExpanded={true}>
									        <AccordionSummary
									          expandIcon={<ExpandMoreIcon />}
									          aria-controls="panel2-content"
									          id="panel2-header"
									        >
									          <h3 sx={{padding: "0"}}><b>{t("Empfohlen für")}</b></h3>
									        </AccordionSummary>
									        <AccordionDetails>															        
												<Grid container >
													<Grid item xs={12}>
														<Grid container spacing={2} sx={{width: 'calc(100% + 32px)'}}>
															<Grid item xs={6} sx={{backgroundColor: "#f2f2f2", borderRight: "2px solid white"}}>
						
																<Box sx={{border: "0px solid #eee", marginTop: "20px",padding: "0px", borderRadius: "0px"}}>
																	<Usefull rating={getModel(leftCar.familie).rating}/>				        		
											        			</Box>
						
															</Grid>
															<Grid item xs={6} sx={{backgroundColor: "hsla(0,0%,94.9%,.5)"}}>
						
																<Box sx={{border: "0px solid #eee", marginTop: "20px",padding: "10px", borderRadius: "0px"}}>
																	<Usefull rating={getModel(rightCar.familie).rating}/>				        		
											        			</Box>
						
						
															</Grid>
														</Grid>
													</Grid>
												</Grid>
									        </AccordionDetails>
										</Accordion>

										<Accordion sx={{padding: "0px"}} defaultExpanded={true}>
									        <AccordionSummary
									          expandIcon={<ExpandMoreIcon />}
									          aria-controls="panel3-content"
									          id="panel3-header"
									        >
									          <h3 sx={{padding: "0"}}><b>{t("Grundriss")}</b></h3>
									        </AccordionSummary>
									        <AccordionDetails>															        
												<Grid container >
													<Grid item xs={12}>
														<Grid container spacing={2} sx={{width: 'calc(100% + 32px)'}}>
															<Grid item xs={6} sx={{backgroundColor: "#f2f2f2", borderRight: "2px solid white"}}>
						
																<Box sx={{border: "0px solid #eee", marginTop: "0px",padding: "0px", borderRadius: "0px"}}>
																	<ReactCompareSlider
																	  ref={{
																	    current: '[Circular]'
																	  }}
																	  boundsPadding={0}
																	  itemOne={<ReactCompareSliderImage alt="Image one" src={leftCar.imageDay}/>}
																	  itemTwo={<ReactCompareSliderImage alt="Image two" src={leftCar.imageNight} style={{backgroundColor: 'white', backgroundImage: '\n linear-gradient(45deg, #ccc 25%, transparent 25%),\n linear-gradient(-45deg, #ccc 25%, transparent 25%),\n linear-gradient(45deg, transparent 75%, #ccc 75%),\n linear-gradient(-45deg, transparent 75%, #ccc 75%)', backgroundPosition: '0 0, 0 10px, 10px -10px, -10px 0px', backgroundSize: '20px 20px'}}/>}
																	  keyboardIncrement="5%"
																	  position={50}
								
																	  transition=".75s ease-in-out"
																	/>					        		
											        			</Box>
												        		
						
						
															</Grid>
															<Grid item xs={6} sx={{backgroundColor: "hsla(0,0%,94.9%,.5)"}}>
						
																<Box sx={{border: "0px solid #eee", marginTop: "0px",padding: "10px", borderRadius: "0px"}}>
																	<ReactCompareSlider
																	  ref={{
																	    current: '[Circular]'
																	  }}
																	  boundsPadding={0}
																	  itemOne={<ReactCompareSliderImage alt="Image one" src={rightCar.imageDay}/>}
																	  itemTwo={<ReactCompareSliderImage alt="Image two" src={rightCar.imageNight} style={{backgroundColor: 'white', backgroundImage: '\n linear-gradient(45deg, #ccc 25%, transparent 25%),\n linear-gradient(-45deg, #ccc 25%, transparent 25%),\n linear-gradient(45deg, transparent 75%, #ccc 75%),\n linear-gradient(-45deg, transparent 75%, #ccc 75%)', backgroundPosition: '0 0, 0 10px, 10px -10px, -10px 0px', backgroundSize: '20px 20px'}}/>}
																	  keyboardIncrement="5%"
																	  position={50}
								
																	  transition=".75s ease-in-out"
																	/>					        		
											        			</Box>
						
						
															</Grid>
														</Grid>
														<em>{t("Grundriss Tag / Nacht")}</em>
													</Grid>
												</Grid>
									        </AccordionDetails>
										</Accordion>

									</Grid>
									<Grid item xs={0} sm={1} md={1} lg={2} xl={2}>
									</Grid>
								</Grid>
							</Grid>
						</Grid>





			      																		
					</Box>

	  				<Box sx={{paddingTop: "40px"}}> 
						<ContactPersons/>
					</Box>
   			</Box>
   		</>
   	)
}

export default function Compare(props) {
	
  const postData = useLocation();
  console.log("POST DATA:", postData.state);	
	
  return <CampingContent props={props} leftCar={postData.state.leftCar} rightCar={postData.state.rightCar}/>;
}