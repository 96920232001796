import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { useInView } from "react-intersection-observer";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Card from '@mui/material/Card';
import { styled } from '@mui/material/styles';

import ImageHotspots from 'react-image-hotspots'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';

import ContactPersons from "../widgets/ContactPersons";
import ModelFamilieTab from '../widgets/ModelFamilieTab';
import CarCard from "../widgets/CarCard";
import FeatureProducts from "../widgets/FeatureProducts";

import Header from "./common/Header2";
import Usefull from "../snippets/Usefull";
import IntroDescriptionPage from "../snippets/IntroDescriptionPage";
import ProductDialog from "../dialogs/ProductDialog";

import models from '../db/models.json';

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

const images = [
  '/hero/hero1Start.jpg',
  '/hero/hero2Start.jpg',
  '/hero/hero3Start.jpg'
];

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  	children: PropTypes.node,
  	index: PropTypes.number.isRequired,
  	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
    	id: `vertical-tab-${index}`,
    	'aria-controls': `vertical-tabpanel-${index}`,
  	};
}


function ExpansionConceptContent(props) {
  	
  	const getCar = (which) => {
		let model;
		models.cars.forEach((value, keys) => {
			if(value.url == which)
		  		model = value;
	  	})
	  	return model;
  	};	
  	
  	const getModel = (which) => {
		let model;
		models.models.forEach((value, keys) => {
			if(value.id == which)
		  		model = value;
	  })
	  
	  return model;
  	};	
  	
	const model = getModel(props.props.expansion);	
	
	const { t, i18n } = useTranslation("",{lng: props.language});
	const [anim, setAnim] = React.useState("animate__animated animate__fadeInUp");
  	const [value, setValue] = React.useState(0);
  	const [product, setProduct] = React.useState("");
  	const [productshow, setProductshow] = React.useState(false);
  	
  	
  	let navigate = useNavigate(); 


  	const handleChange = (event, newValue) => {
    	setValue(newValue);
  	};  	

	const callUrl = (url) => {
		navigate(url);
	};
	
   	const hotspots=[{ name: "Sitzbank",
	   				 x: 18, 
   					 y: 50, 
   					 content: <div onClick={e => handleProduct("Sitzbank")} class="blobX"></div>,
					 slogan: "Komplettset SK 7: Sitz-/Schlafbank in einem",
			         image: "/assets/items/sitzbank.png"
   				   },
					{ name: "Kochfeld",
	   				 x: 60, 
   					 y: 37, 
   					 content: <div onClick={e => handleProduct("Kochfeld")} class="blobX"></div>,
					 slogan: "Kombination aus 2-flammigem Kochfeld und Spüle",
			         image: "/assets/items/kochfeld.png"
   				   },
				   { name: "Kühlschrank",
 					 x: 75, 
				     y: 70, 
				     content: <div onClick={e => handleProduct("Kühlschrank")} class="blobX"></div>,
					 slogan: "65 l Kühlschrank mit Tür und Gefrierfach",
			         image: "/assets/items/kuehlschrank.png"
				   }
	];

	const handleProduct = (which) => {
		let product;
		hotspots.forEach((value, keys) => {
			if(value.name == which)
		  		product = value;
	  })
		setProduct(product);
		setProductshow(true);
	};

	const [ref0, inView0] = useInView({
	    triggerOnce: true,
	    rootMargin: '0px 0px',
	});


  	return (<>
			  	<Header heroImages={images} label={t("Ausbaukonzept") + ": " + model.label} slogan={t("FürjedendenpassendenAusbau")} breadcrumb={props.props.breadcrumb} embedded={props.props.embedded}/>

				<Box sx={{ flexGrow: 1, display: {
							xs: 'block',
							sm: 'block',  
							md: 'none',  
							lg: 'none',  
							xl: 'none',  
							
							} }}
				>
					<Box class="wrapper" style={{padding: "65px"}}>
					</Box> 
				</Box>
				
				<Box sx={{ flexGrow: 1, display: {
							xs: 'none',
							sm: 'none',  
							md: 'block',  
							lg: 'block',  
							xl: 'block',  
							
							} }}
				>

	  				<Box sx={{paddingTop: "0px"}}> 

						{models.models.map((model, id) => (
							<>
								{props.props.expansion == model.url ? (							
									<Box sx={{ marginBottom: "50px"}} id={model.id}>
										<Box  >
											<ModelFamilieTab model={model} models={models} />								
											<hr className="spacer"/>
										</Box>
									</Box>
									): (<></>)}
							</>
						))}
						
						<Grid container style={{paddingTop: "0px"}}>
			        		<Grid item xs={12}>
					      		<Grid container spacing={2}>
					        		<Grid item xs={2}>
						     	   </Grid>
					        		<Grid item xs={8}>
										<Box sx={{}}>
											<h2>{t("The big picture")}</h2>
										</Box>

										<IntroDescriptionPage description={t("Hinter der Idee zum Weekender steckt ein Freizeit/Wochendend Camper mit Alltagstauglichkeit zu kombinieren. So bietet das entnehmbare Küchenmodul die Möglichkeit zum einen Outdoorcooking, ebenfalls bei Bedarf Platz für den Transport zu schaffen.")}/>

						     	   </Grid>
					        		<Grid item xs={2}>
						     	   </Grid>
					     	   </Grid>
				     	   </Grid>
			     	   	</Grid>	


						<Box className="imgHotspot">
							<ImageHotspots
									class="imgHotspotX"
							        src="/assets/t6-triostyle/slider1.jpg"
							        alt="sample"
							        hideFullscreenControl={true}
							        hideZoomControls={true}
							        hotspots={hotspots}
							      />
						</Box>
						
						<Box sx={{background: "hsla(0,0%,94.9%,.5)", minHeight: "50px", marginTop: "50px"}}>
						
						<Grid container style={{paddingTop: "40px"}}>
			        		<Grid item xs={12}>
					      		<Grid container spacing={2}>
					        		<Grid item xs={2}>
						     	   </Grid>
					        		<Grid item xs={4}>
										<Box display="flex" justifyContent="flex-end">
											<h2>{t("UnsereOptionalenEmpfehlungen")}</h2>
										</Box>
						     	   </Grid>
					        		<Grid item xs={4}>
						        		<Swiper style={{height: "500px"}}
															direction={'vertical'}
													        slidesPerView={1}
													        spaceBetween={30}
															navigation={true}
													        pagination={{
													          clickable: true,
													        }}
													        modules={[ Navigation]}
													>
														{model.recommendedProducts.map((item) => (
												        	<SwiperSlide>
												        	
												        		<center>
																	<Card sx={{border: "none", boxShadow: "none", backgroundColor: "inherit", paddingTop: "30px"}} >

													  					<FeatureProducts title={item.title} slogan={item.slogan} description={item.description} image={item.image}/>
													        	
													        		</Card>
													        	</center>
					
												        	</SwiperSlide>
												        ))}		
										</Swiper>
						     	   </Grid>
					        		<Grid item xs={2}>
						     	   </Grid>
					     	   </Grid>
				     	   </Grid>
			     	   	</Grid>							
						
						</Box>
					
						
						<Grid container style={{paddingTop: "40px"}}>
			        		<Grid item xs={12}>
					      		<Grid container spacing={2}>
					        		<Grid item xs={2}>
						     	   </Grid>
					        		<Grid item xs={8}>
										<Box sx={{}}>
											<h2>{t("IstErRichtig", {konzept: model.label})}</h2>
											<h3>{t("Unsere Einschätzung für verschiedene Szenarien")}</h3>
										</Box>
										<Box sx={{paddingLeft: "20px"}}>
											<Usefull rating={model.rating}/>
										</Box>
						     	   </Grid>
					        		<Grid item xs={2}>
						     	   </Grid>
					     	   </Grid>
				     	   </Grid>
			     	   	</Grid>	
					
					
						
						<Grid container style={{paddingTop: "40px"}}>
			        		<Grid item xs={12}>
					      		<Grid container spacing={2}>
					        		<Grid item xs={2}>
						     	   </Grid>
					        		<Grid item xs={8}>
						  				
					        			{inView0 === true ? (										
											<>											
										<Box sx={{}}>
											<h2>{t("SchlüsselfertigeFahrzeugeaufBasisdesAusbaukonzept", {konzept: model.label})}</h2>
										</Box>
												{model.tabs.map((tab, id) => (
													<>											
														{id == 3 ? (<>
												        	<Box style={{minHeight: "600px;"}}>
														
																<Box  sx={{marginTop: "30px",  height: "100%"}}>
																	<Box  sx={{position: "flex-start", bottom: "10px"}} justify="flex-end">
																		{anim == "" ? (<></>) : (
																			<>
																			<div class={anim} style={{ marginLeft: "10px"}}>
																				<Box display="flex" justifyContent="flex-start">
																					<Stack spacing={{ xs: 1, sm: 2, marginBottom: "10px" }} justify="flex-end" direction="row" useFlexGap flexWrap="wrap">
																						{tab.baseCars.map((car, index) => (
																								<>	  
																									<CarCard model={getCar(car.url, index)}/>
																								</>
																						))}																							
																					</Stack >
																				</Box>
																			</div >
																			</>
																		)}			
																	</Box>
																</Box>
																</Box>
															</>
													):(<></>)}											
													</>
												))}										
												</>
										):(<></>)}											
										
									<span ref={ref0} />

						     	   </Grid>
					        		<Grid item xs={2}>
						     	   </Grid>
					     	   </Grid>
				     	   </Grid>
			     	   	</Grid>	
						
						<Box sx={{ marginTop: "50px"}} >
							<hr className="spacer"/>
							<center>
								<Button autoFocus 
										variant="contained"
										onClick={e => callUrl("/modelfamilie")}>
						            {t("Übersicht Aller Ausbaukonzepte")}
						        </Button>
							</center>

						</Box>

						
						
		  				<Box sx={{paddingTop: "40px"}}> 
							<ContactPersons profil={props.props.profil}/>
						</Box>
	
					</Box>
  				
					<ProductDialog open={productshow} handleOpen={setProductshow} product={product}/>

   			</Box>
   		</>
   	)
}

export default function ExpansionConcept(props) {
  return <ExpansionConceptContent props={props}/>;
}