import React from "react";
import Box from '@mui/material/Box';
import { useInView } from "react-intersection-observer";
import { Zoom } from 'react-slideshow-image';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import TopDrawer from './TopDrawer';
import TopContent from './TopContent';

import 'react-medium-image-zoom/dist/styles.css'
import 'react-slideshow-image/dist/styles.css'

const buttonStyle = {
    width: "50px",
    height: "160px",
    background: 'none',
    border: '0px',
    marginLeft: "10px",
    marginRight: "20px"
};

const divStyle = {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	backgroundSize: 'cover',
	height: '66vH'
}

const properties = {
    prevArrow: <button style={{ ...buttonStyle }}><ArrowBackIosIcon sx={{width: "3em", height: "3em", fill: "#fff"}}/></button>,
    nextArrow: <button style={{ ...buttonStyle }}><ArrowForwardIosIcon sx={{width: "3em", height: "3em", fill: "#fff"}}/></button>
}

const Slideshow = (props) => {
    return (
      <div className="slide-container" style={{height: "66vH"}}>
        <Zoom scale={0.2} easing="cubic-out" infinite={false} {...properties}>
          {
			props.heroImages.map((each, index) => (
				<>
					<Box className="heroAdvice" style={{marginTop: "45vH"}}>
							<center>
		                		<h2 class="heroHeadline"><span style={{backgroundColor: "rgba(100,100,100,0.5)", paddingLeft: "10px", paddingRight: "10px", paddingBottom: "4px"}}>{props.label}</span></h2>
								<h3 class="heroSlogan"><span style={{backgroundColor: "rgba(100,100,100,0.5)", paddingLeft: "10px", paddingRight: "10px", paddingBottom: "4px"}}>{props.slogan}</span></h3>
	                		</center>
					</Box>
					<div key={index}>
	              		<Box  class={each + " galleryImg "} style={{ ...divStyle }}>
  		               	</Box>
            		</div>				
				</>
			))

          }
        </Zoom>
      </div>
    )
}


export default function Header2(props) {
  	const [page, setPage] = React.useState("home");

  	const change = (which) => {
		setPage(which);
	}
	
	const [ref, inView] = useInView({
	    triggerOnce: false,
	    rootMargin: '0px 0px',
	});

  	return (<>
				<Box className="heroAdvice" >
					<TopContent embedded={props.embedded}/>
				</Box>
				<span ref={ref} />
				<Slideshow heroImages={props.heroImages} label={props.label} slogan={props.slogan}/>
				<TopDrawer show={inView === true ? false : true} change={change} page={page} embedded={props.embedded}/>
			</>
	  )
}